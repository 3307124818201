import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext } from 'react';
import { fontFamily, primaryColor, whiteColor } from '../../Utils/Constants';
import { DataContext } from '../../Context/DataContext';
import textEn from '../../I18n/text.en';
import textId from '../../I18n/text.id';

const styleToggleActive = {
  backgroundColor: primaryColor,
  color: whiteColor
}

const styleToggleInactive = {
  backgroundColor: whiteColor,
  color: primaryColor
}

const ToggleLanguage = () => {

  const { allState } = useContext(DataContext);
  const { locale, setText, setLocale } = allState;

  const handleChange = (e) => {
    if (e.target.value === "en") {
      setText(textEn);
      setLocale("en");
    } else {
      setText(textId);
      setLocale("id");
    }
    localStorage.setItem("lang", e.target.value);
  }

  return (
    <ToggleButtonGroup
      value={locale}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size='small'
      style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', fontFamily: fontFamily }}
    >
      <ToggleButton value="id" style={locale === "id" ? styleToggleActive : styleToggleInactive}>ID</ToggleButton>
      <ToggleButton value="en" style={locale === "en" ? styleToggleActive : styleToggleInactive}>EN</ToggleButton>
    </ToggleButtonGroup >
  );
};

export default ToggleLanguage;