import { Container, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import CopyRight from '../Copyright/Copyright';
import { styleContainer, styleTitle, styleSentences } from './FooterStyle';
import { FormattedMessage } from 'react-intl';
import { DataContext } from '../../Context/DataContext';
import { useNavigate } from 'react-router-dom';


const Footer = () => {
    const navigate = useNavigate();
    const { allState } = useContext(DataContext)
    const { setIsAbout } = allState;

    const handleLanding = () => {
        window.location.href = "./"
    }

    const navigateToAbout = () => {
        setIsAbout(true);
        navigate('/');
    }

    const navigateToHelp = () => {
        navigate('/help');
    }

    const navigateInstagram = () => {
        window.location.href = "https://www.instagram.com/mybalirentcar/"
    }

    // const navigateToLocation = () => {
    //     navigate('/location');
    // }

    return (
        <>
            <Container style={styleContainer} maxWidth>
                <div>
                    <Grid container >
                        <Grid item xs={12} md={3}>
                            <div style={{ marginTop: "5%" }}>
                                <img alt='gambar' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} src={'images/logo2.png'} width="80%" onClick={handleLanding} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='h6' style={styleTitle}>
                                            <FormattedMessage id="company" />
                                        </Typography>
                                        <br />
                                        <Typography variant='h6' style={styleSentences} onClick={navigateToAbout}>
                                            <FormattedMessage id="aboutUs" />
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='h6' style={styleTitle}>
                                            <FormattedMessage id="contactUs" />
                                        </Typography>
                                        <br />
                                        <Typography variant='h6' style={styleSentences} onClick={navigateToHelp}>
                                            <FormattedMessage id="help" />
                                        </Typography>
                                        <Typography variant='h6' style={styleSentences}>
                                            <FormattedMessage id="location" />
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <div style={{ textAlign: "left" }}>
                                        <Typography variant='h6' style={styleTitle}>
                                            <FormattedMessage id="socialMedia" />
                                        </Typography>
                                        <br />
                                        <Typography variant='h6' style={styleSentences} onClick={navigateInstagram}>
                                            <FormattedMessage id="instagram" />
                                        </Typography>
                                        <Typography variant='h6' style={styleSentences}>
                                            <FormattedMessage id="tiktok" />
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={0} md={0.5}>

                        </Grid>
                        <Grid item xs={11}>
                            <CopyRight />
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    );
};

export default Footer;