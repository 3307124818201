import { Container, Grid, Button, Hidden } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardItem from '../../Components/Card/CardItem/CardItem';
import { primaryColor, secondaryColor, whiteColor } from '../../Utils/Constants';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { getBestCar } from '../../Components/api/car';
import { Skeleton } from 'antd';

const styleContainer = {
  padding: "20px"
};

const handleDetail = () => {
  window.location.href = "./all-items"
}

const ItemRent = (props) => {
  const { navigateToCheckout } = props;
  const [listCar, setListCar] = useState([])

  useEffect(() => {
    getBestCar()
      .then((res) => {
        setListCar(res.data.data);
      })
  }, [])

  return (
    <>
      <Container style={styleContainer} grid>
        <Hidden smDown>
          <TypographyCustom
            variant="h3"
            content="bodyBestDeals"
            style={{ fontWeight: "bold", color: primaryColor }}
          />
        </Hidden>
        <Hidden mdUp>
          <TypographyCustom
            variant="h4"
            content="bodyBestDeals"
            style={{ fontWeight: "bold", color: primaryColor }}
          />
        </Hidden>

        <div style={{ marginTop: "20px" }}>
          <Grid container>
            <Grid key={1} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
            <Grid key={2} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
            <Grid key={3} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
          </Grid>
          <Grid container spacing={4} alignItems={"center"} justifyContent={"center"}>
            {
              listCar.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                    <CardItem
                      name={item.name}
                      priceDay={item.pricePerDay}
                      priceMonth={item.pricePerMonth}
                      priceDayUp={item.pricePerDayUp}
                      priceMonthUp={item.pricePerMonthUp}
                      image={item.image}
                      seat={item.maxPeople}
                      fuel={item.fuel}
                      type={item.type}
                      id={item.id}
                      ready={item.ready}
                      navigateToCheckout={navigateToCheckout}
                      priceFont="h6"
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        </div>
        <Hidden smDown>
          <Button onClick={handleDetail} style={{ backgroundColor: secondaryColor, color: whiteColor, borderRadius: "20px", margin: "40px 0px 0px 0px", width: '50%' }} variant="contained">{'Lihat Lebih Banyak'}</Button>
        </Hidden>
        <Hidden mdUp>
          <Button onClick={handleDetail} style={{ backgroundColor: secondaryColor, color: whiteColor, borderRadius: "20px", margin: "40px 0px 0px 0px", width: '100%' }} variant="contained">{'Lihat Lebih Banyak'}</Button>
        </Hidden>
      </Container>
    </>
  );
};

export default ItemRent;