import React, { useContext, useEffect, useRef } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Box, Button, Container, Grid, Paper } from '@mui/material';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { Form, Input } from 'antd';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage, useIntl } from 'react-intl';
import TextArea from 'antd/es/input/TextArea';
import { DataContext } from '../../Context/DataContext';

const Help = () => {
  const intl = useIntl();
  const locationRef = useRef(null);
  const { allState } = useContext(DataContext)
  const { isLocation } = allState;

  useEffect(() => {
    if (isLocation) {
      locationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLocation])

  return (
    <div>
      <NavBar title="navbarHelp" />
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={0} md={1}>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box sx={{ marginBlock: { xs: '0px', sm: '4rem'} }}>
              <Paper style={{ padding: "20px", textAlign: "start" }} elevation={10}>
                <TypographyCustom
                  variant={"h5"}
                  content="helpNeedHelp"
                  style={{ color: primaryColor, fontWeight: "700" }}
                />
                <TypographyCustom
                  variant={"body1"}
                  content="helpNeedHelpCaption"
                // style={{ color: primaryColor, fontWeight: "700" }}
                />
                <br />

                <Form
                  layout="vertical"
                >
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                      <Form.Item label={<FormattedMessage id='helpName' />} >
                        <Input placeholder={intl.formatMessage({ id: 'helpNamePlaceholder' })} />
                      </Form.Item>
                      <Form.Item label={<FormattedMessage id='helpEmail' />} >
                        <Input placeholder={intl.formatMessage({ id: 'helpEmailPlaceholder' })} />
                      </Form.Item>
                      <Form.Item label={<FormattedMessage id='helpPhoneNumber' />} >
                        <Input placeholder={intl.formatMessage({ id: 'helpPhoneNumberPlaceholder' })} />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Form.Item label={<FormattedMessage id='helpMessage' />} >
                        <TextArea rows={6} placeholder={intl.formatMessage({ id: 'helpMessagePlaceholder' })} />
                      </Form.Item>
                      <div style={{ textAlign: "end" }}>
                        <Button style={{ backgroundColor: primaryColor, color: whiteColor, width: "40%", borderRadius: "5rem" }}>
                          <TypographyCustom
                            content="helpSend"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>


                </Form>
                {/* <br />
                <MapComponent /> */}
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={0} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Help;