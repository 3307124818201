const textId = {
  // NAVBAR
  navbarHome: "Beranda",
  navbarSelectVehicle: "Pilih Kendaraan",
  navbarPriceList: "Daftar Harga",
  navbarHelp: "Bantuan",
  navbarBlog: "Blog",
  navbarTransaction: "Daftar Transaksi",
  navbarOutcomeTransaction: 'Daftar Transaksi Pengeluaran',
  navbarListVehicle: "Daftar Kendaraan",

  // HEADER
  welcomeHeaderPartOne: "Sewa kendaraan di Bali mulai",
  welcomeHeaderPartTwo: "100",
  welcomeHeaderPartThree: "ribuan saja",
  bodyJargon: "Sewa kendaraan dimanapun dan kapanpun dengan mudah",
  benefitHeaderOne: "Bisa Antar dimana Saja",
  benefitHeaderTwo: "Gratis Pembatalan",
  benefitHeaderThree: "100+ Mobil dan Motor",
  benefitCaptionOne: "Kami bisa mengantar kendaraan ke lokasi anda. Biaya pengantaran gratis untuk beberapa lokasi tertentu",
  benefitCaptionTwo: "Gratis pembatalan sewa kendaraan jika anda berubah pikiran. Tidak ada biaya pembatalan",
  benefitCaptionThree: "Kami memiliki lebih dari 100 kendaraan yang bisa anda sewa. Mulai dari mobil hingga motor",

  // HEADER FORM
  headerFormRentNow: "Pesan Sekarang",
  headerFormPickUpLocation: "Lokasi Antar Kendaraan",
  headerFormPickUpLocationPlaceholder: "Pilih Lokasi",
  headerFormPickUp: "Mulai Sewa",
  hedaerFormPickUpPlaceHolder: "Pilih Tanggal",
  hedaerFormPickUpTimePlaceHolder: "Pilih Waktu",
  headerFormDropOff: "Selesai Sewa",
  headerFormDropOffPlaceHolder: "Pilih Tanggal",
  headerFormDropOffTimePlaceHolder: "Pilih Waktu",
  headerFormContinueBooking: "Lanjutkan Pemesanan",
  headerFormSubmit: "Submit",
  seePriceList: "Daftar Harga →",

  // CHECKOUT
  checkoutForm: "Checkout",
  checkoutVehicle: "Kendaraan",
  checkoutVoucher: "Kode Voucher",
  checkoutVoucherPlaceholder: "Masukkan Kode Voucher",
  checkoutTypeVehicle: "Tipe Kendaraan",
  checkoutTypeMatic: "- Matic",
  checkoutTypeManual: "- Manual",
  checkoutTypeElectric: "- Electric",
  checkoutTermAndCondition: "Saya setuju dengan syarat dan ketentuan",
  checkoutReadTermAndCondition: "Baca syarat dan ketentuan",
  checkoutPaymentDetail: "Rincian Pembayaran",
  checkoutPaymentRentPrice: "Harga Sewa",
  checkoutPaymentDiscountVoucher: "Diskon Voucher",
  checkoutPaymentAdminFee: "Biaya Admin",
  checkoutPaymentTotal: "Total",
  checkoutName: "Nama Penerima",
  checkoutNamePlaceholder: "Masukkan Nama Penerima",
  checkoutEmail: "Email",
  checkoutEmailPlaceholder: "Masukkan Email",
  checkoutPhoneNumber: "Nomor Telepon (WhatsApp)",
  checkoutPhoneNumberPlaceholder: "Masukkan Nomor Telepon",
  checkoutPaymentMethod: "Metode Pembayaran",
  bri: "BRI",
  bca: "BCA",
  mandiri: "Mandiri",
  accountBankName: "a.n Kadek Dwi Bagus",
  or: "atau",

  // ORDER
  orderMyOrder: "Pesanan Saya",
  orderNumber: "No. Pesanan",
  orderStatus: "Status",
  orderTransferBefore: "Transfer sebelum",
  orderWaitingPayment: "Menunggu pembayaran",
  orderPaymentSuccess: "Pembayaran berhasil",
  orderName: "Nama penerima :",
  orderEmail: "Email :",
  orderPhoneNumber: "Nomor telepon :",
  orderVehicle: "kendaraan :",
  orderTypeVehicle: "Tipe Kendaraan :",
  orderPickUp: "Mulai sewa :",
  orderDropOff: "Selesai sewa :",
  orderLocation: "Lokasi antar :",
  orderVoucher: "Kode voucher :",
  orderChangePaymentMethod: "Ubah metode pembayaran",
  orderChangeOrderDetail: "Ubah detail pesanan",
  orderChangeVehicle: "Ubah kendaraan",

  // BODY LANDING
  bodyBestDeals: "Penawaran Terbaik",

  // FOOTER
  greeting: 'Hallo, {name}!',
  instagram: 'Instagram',
  tiktok: 'Tiktok',
  whatsapp: 'Whatsapp',
  aboutUs: 'Tentang Kami',
  contactUs: 'Hubungi Kami',
  help: 'Bantuan',
  location: 'Lokasi',
  socialMedia: 'Media Sosial',
  company: 'Perusahaan',

  // ITEM
  itemOrderVehicle: "Pesan",

  // LIST ITEM 
  listItemVehicle: "Kendaraan",
  listItemVehicleAll: "Semua Kendaraan",
  listItemVehicleItem1: "Motor",
  listItemVehicleItem2: "Mobil",
  listItemTypeVehicle: "Tipe kendaraan",
  listItemTypeVehicleAll: "Semua Tipe",
  listItemTypeVehicleItem1: "Matic",
  listItemTypeVehicleItem2: "Manual",
  listItemTypeVehicleItem3: "Electric",

  // PRICE LIST
  priceListVehicle: "Kendaraan",
  priceListName: "Nama",
  priceListTypeVehicle: "Tipe",
  priceListPrice: "Harga",
  priceListPricePerDay: "Harga/hari",
  priceListPricePerMonth: "Harga/bulan",
  priceListDetail: "Detail",

  // HELP
  helpNeedHelp: "Butuh Bantuan?",
  helpNeedHelpCaption: "Masukkan pertanyaan anda dan My Bali Rent Car akan secepat mungkin menghubungi anda",
  helpName: "Nama",
  helpNamePlaceholder: "Masukkan Nama",
  helpEmail: "Email",
  helpEmailPlaceholder: "Masukkan Email",
  helpPhoneNumber: "Nomor Telepon (WhatsApp)",
  helpPhoneNumberPlaceholder: "Masukkan Nomor Telepon",
  helpMessage: "Pesan",
  helpMessagePlaceholder: "Masukkan Pesan",
  helpSend: "Kirim",

  // util
  validateNik: "Validasi NIK",

  // TNC
  tncTitle: "Syarat dan Ketentuan",
  tnc1: "Lisensi mengemudi Internasional diperlukan untuk orang asing dan SIM A untuk orang Indonesia.",
  tnc2: "Tarif sewa sudah termasuk asuransi kendaraan. Kerusakan akibat kecelakaan atau pencurian kendaraan atau bagian dari kendaraan menjadi tanggung jawab penyewa hingga $300 (Tiga ratus dolar Amerika Serikat).",
  tnc3: "Harga sewa kendaraan dihitung untuk penggunaan per hari (24 jam) dengan layanan sewa kendaraan, Self Drive Excess waktu penggunaan mobil atau lembur sewa mobil akan dikenakan biaya tambahan sebesar Rp. 30.000 per jam dari harga normal sewa mobil yang bersangkutan. Lembur maksimum adalah 2 jam. Lembur lebih dari 2 jam akan dikenakan biaya sewa mobil sehari penuh.",
  tnc4: "Penyewa bertanggung jawab penuh atas kerusakan, pencurian kendaraan, atau bagian apapun darinya jika kendaraan dikendarai tidak di jalan biasa (misalnya pantai, lapangan, dll).",
  tnc5: "Penyewa bertanggung jawab penuh atas konsekuensi yang ditimbulkan karena membiarkan atau memberikan kendaraan untuk dikendarai oleh orang lain selain penyewa.",
  tnc6: "Penyewa tidak dapat memberikan kendaraan untuk dikendarai oleh orang lain dan tidak diizinkan untuk mengendarai kendaraan ke luar pulau tanpa persetujuan perusahaan rental.",
  tnc7: "Pertimbangan khusus di atas hak sewa untuk membatalkan sewa secara sepihak bahkan jika perjanjian sudah ditandatangani.",
  tnc8: "US $ 20 / 50 / 100 / 200 akan dikenakan biaya jika Anda kehilangan kunci kontak.",
  tnc9: "US $ 5 / 80 akan dikenakan biaya jika Anda kehilangan registrasi kendaraan.",
  tnc10: "Penyewa harus memberikan foto copy paspor dan SIM.",
  tnc11: "Foto penyewa akan diambil untuk keperluan identifikasi.",
  tnc12: "Biaya pembatalan adalah 50% (lima puluh persen) dari biaya yang disepakati dan tidak dapat dikembalikan jika Anda berubah pikiran.",

  // DUMMY TEXT
  dummyText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae diam euismod, aliquam nisl ac, tincidunt sapien. Nul",
  dummyTnc: "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae diam euismod, aliquam nisl."
};

export default textId;
