
import React from 'react';
import { fontFamily } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

const styleDefault = {
  fontFamily: fontFamily
}

const TypographyCustom = (props) => {
  const { content, variant, style } = props;
  const newStyle = { ...styleDefault, ...style };
  return (
    <Typography variant={variant} style={newStyle}>
      <FormattedMessage id={content} />
    </Typography>
  );
};

export default TypographyCustom;