import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { Grid, Paper, Button, Container, Typography, Box } from '@mui/material';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { Checkbox, DatePicker, Form, Input, Radio, Select, Space, TimePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LINK_NUMBER_WA, fontFamily, greenColor, primaryColor, whiteColor } from '../../Utils/Constants';
import Footer from '../../Components/Footer/Footer';
import { DataContext } from '../../Context/DataContext';
import { getAllCar } from '../../Components/api/car';
import { useNavigate } from 'react-router-dom';
import { formatPrice, textWaOrder } from '../../Utils/functionUtils';
import ModalTnc from '../../Components/Modal/ModalTnc';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'

const generateSelectPayment = (bank) => {
  return (
    <div style={{ display: 'flex' }}>
      <img src={`images/payment/${bank}.png`} alt={bank} width={50} />
      <div style={{ marginLeft: '1rem', marginTop: "0.2rem" }}>
        <TypographyCustom
          variant={"body2"}
          content={bank}
          style={{ textAlign: 'start', fontWeight: "700" }}
        />
        <TypographyCustom
          variant={"body2"}
          content="accountBankName"
          style={{ textAlign: 'start', fontWeight: "400" }}
        />
      </div>
    </div>
  );
}

const Checkout = () => {
  // Create swal object
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const { allState } = useContext(DataContext);
  const { orderForm, setOrderForm, locale } = allState;
  const [listCar, setListCar] = useState([]);
  const [listTypeCar, setListTypeCar] = useState([]);
  const [vehicle, setVehicle] = useState(orderForm.vehicle || '');
  const [type, setType] = useState(orderForm.typeVehicle || '');
  const [image, setImage] = useState(orderForm.image || '');
  const [payment, setPayment] = useState(orderForm.payment || '');
  const [pickUpDate, setPickUpDate] = useState(orderForm.pickUpDate || '');
  const [tnc, setTnc] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (listCar.length === 0) {
      getAllCar()
        .then((res) => {
          const listName = res.data.data.map((item) => {
            return {
              value: item.name,
              label: item.name
            }
          });
          const listType = res.data.data.map((item) => {
            return {
              name: item.name,
              type: item.type,
              image: item.image
            }
          });
          setListCar(listName);
          setListTypeCar(listType);
        })
    }
  }, [listCar, listTypeCar])

  const checkValues = (values) => {
    if (!values.vehicle) {
      return 'Please select vehicle';
    }
    if (!values.name) {
      return 'Please input your name';
    }
    if (!values.email) {
      return 'Please input your email';
    }
    if (!values.phoneNumber) {
      return 'Please input your phone number';
    }
    if (!values.location) {
      return 'Please input your location';
    }
    if (!values.pickUpDate) {
      return 'Please select pick up date';
    }
    if (!values.pickUpTime) {
      return 'Please select pick up time';
    }
    if (!values.dropOffDate) {
      return 'Please select drop off date';
    }
    if (!values.dropOffTime) {
      return 'Please select drop off time';
    }
    if (!tnc) {
      return 'Please read and check term and condition';
    }
    if (!payment) {
      return 'Please select payment';
    }
    return null;
  }

  const handleChange = (value) => {
    setVehicle(value);
    const type = listTypeCar.find((item) => item.name === value);
    setType(type.type);
    setImage(type.image);
  };

  const handleChangePickUpDate = (value) => {
    setPickUpDate(value);
  }

  const handleSelectPayment = (e) => {
    setPayment(e.target.value);
  }

  const handleCheckout = (values) => {
    MySwal.showLoading()
    const error = checkValues(values);
    if (error) {
      MySwal.fire({
        title: <strong>Checkout Failed</strong>,
        html: <i>{error}</i>,
        showConfirmButton: false,
        timer: 1500,
        icon: 'error'
      })
      return;
    } else {
      setOrderForm({
        vehicle: values.vehicle,
        typeVehicle: type,
        name: values.name,
        email: values.email,
        location: values.location,
        phoneNumber: values.phoneNumber,
        image: image,
        payment: payment,
        pickUpDate: values.pickUpDate,
        pickUpTime: values.pickUpTime,
        dropOffDate: values.dropOffDate,
        dropOffTime: values.dropOffTime,
      });
      MySwal.fire({
        title: <strong>Checkout Success</strong>,
        timer: 1000,
        showConfirmButton: false,
        icon: 'success'
      });
      navigate('/order');
    }
  }



  return (
    <div>
      <NavBar />
      <Container style={{ marginBottom: "2rem" }}>
        <Form
          layout="vertical"
          onFinish={handleCheckout}
        >
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Box sx={{ width: { xs: "100%", md: "90%" } }}>
                <Paper style={{ padding: "20px", marginTop: "40px" }} elevation={2}>
                  <TypographyCustom
                    variant={"h5"}
                    content="checkoutForm"
                    style={{ color: primaryColor, fontWeight: "700" }}
                  />
                  <br />
                  <Form.Item style={{ fontFamily: fontFamily }} name={'vehicle'} label={<FormattedMessage id="checkoutVehicle" />} >
                    <Select
                      style={{
                        width: '100%',
                        textAlign: 'start'
                      }}
                      defaultValue={orderForm.vehicle}
                      value={orderForm.vehicle}
                      onChange={handleChange}
                      options={listCar}

                    />
                  </Form.Item>
                  <Form.Item style={{ fontFamily: fontFamily }} name={'typeVehicle'} label={<FormattedMessage id="checkoutTypeVehicle" />} >
                    {
                      type ?
                        <TypographyCustom
                          variant={"body2"}
                          content={type === 'MATIC' ? "checkoutTypeMatic" : "checkoutTypeManual"}
                          style={{ textAlign: 'start' }}
                        />
                        : <>-</>
                    }
                  </Form.Item>
                  <Form.Item initialValue={orderForm.name} name={'name'} label={<FormattedMessage id='checkoutName' />} >
                    <Input placeholder={intl.formatMessage({ id: 'checkoutNamePlaceholder' })} />
                  </Form.Item>
                  <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.email} name={'email'} label={<FormattedMessage id='checkoutEmail' />} >
                    <Input placeholder={intl.formatMessage({ id: 'checkoutEmailPlaceholder' })} />
                  </Form.Item>
                  <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.phoneNumber} name={'phoneNumber'} label={<FormattedMessage id='checkoutPhoneNumber' />} >
                    <Input placeholder={intl.formatMessage({ id: 'checkoutPhoneNumberPlaceholder' })} />
                  </Form.Item>
                  <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.location} label={<FormattedMessage id='headerFormPickUpLocation' />} name={'location'}>
                    <Input placeholder={intl.formatMessage({ id: 'headerFormPickUpLocationPlaceholder' })} />
                  </Form.Item>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.pickUpDate} name='pickUpDate' label={<FormattedMessage id='headerFormPickUp' />}>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'hedaerFormPickUpPlaceHolder' })}
                          style={{ width: "100%" }}
                          onChange={handleChangePickUpDate}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.pickUpTime} name='pickUpTime' label={' '}>
                        <TimePicker
                          placeholder={intl.formatMessage({ id: 'hedaerFormPickUpTimePlaceHolder' })}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.dropOffDate} name='dropOffDate' label={<FormattedMessage id='headerFormDropOff' />}>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'headerFormDropOffPlaceHolder' })}
                          style={{ width: "100%", marginRight: "3%" }}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.dropOffTime} name='dropOffTime' label={' '}>
                        <TimePicker
                          placeholder={intl.formatMessage({ id: 'headerFormDropOffTimePlaceHolder' })}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                  <Form.Item style={{ fontFamily: fontFamily }} initialValue={orderForm.voucherCode} name={'voucherCode'} label={<FormattedMessage id='checkoutVoucher' />} >
                    <Input placeholder={intl.formatMessage({ id: 'checkoutVoucherPlaceholder' })} />
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex" }}>
                      <Checkbox name='checkTnc' value={tnc} onChange={() => setTnc(!tnc)} style={{ marginRight: '1rem' }} />
                      <TypographyCustom
                        variant={"body2"}
                        content="checkoutTermAndCondition"
                        style={{ textAlign: 'start' }}
                      />
                    </div>
                    <div
                      onClick={() => setModalOpen(true)}
                    >
                      <TypographyCustom
                        variant={"body2"}
                        content="checkoutReadTermAndCondition"
                        style={{ textAlign: 'start', color: primaryColor, fontWeight: "700" }}
                      />
                    </div>
                  </Form.Item>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ width: { xs: "100%", md: "90%" } }}>
                <Paper style={{ padding: "20px", marginTop: "40px" }} elevation={2}>
                  <img src="images/car-header.png" alt="car" style={{ width: "100%", marginTop: "-30px" }} />
                  <Typography variant='body1' style={{ textAlign: 'start' }}>
                    <FormattedMessage id="checkoutPaymentMethod" />
                  </Typography>
                  <div style={{ textAlign: 'start', marginBottom: '2rem' }}>
                    <Radio.Group value={payment} onChange={handleSelectPayment}>
                      <Space direction="vertical">
                        <Radio value={'bca'}>
                          {generateSelectPayment('bca')}
                        </Radio>
                        <Radio value={'bri'}>
                          {generateSelectPayment('bri')}
                        </Radio>
                        <Radio value={'mandiri'}>
                          {generateSelectPayment('mandiri')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </div>
                  <Typography variant='body1' style={{ textAlign: 'start' }}>
                    <FormattedMessage id="checkoutPaymentDetail" />
                  </Typography>
                  <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <TypographyCustom
                      variant={"body2"}
                      content="checkoutPaymentRentPrice"
                      style={{ textAlign: 'start' }}
                    />
                    <TypographyCustom
                      variant={"body2"}
                      content={`Rp ${formatPrice(orderForm.price)}`}
                      style={{ textAlign: 'start', color: primaryColor }}
                    />
                  </div>
                  <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <TypographyCustom
                      variant={"body2"}
                      content="checkoutPaymentDiscountVoucher"
                      style={{ textAlign: 'start' }}
                    />
                    <TypographyCustom
                      variant={"body2"}
                      content="- Rp 0"
                      style={{ textAlign: 'start', color: primaryColor }}
                    />
                  </div>
                  <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <TypographyCustom
                      variant={"body2"}
                      content="checkoutPaymentAdminFee"
                      style={{ textAlign: 'start' }}
                    />
                    <TypographyCustom
                      variant={"body2"}
                      content="Rp 2.000"
                      style={{ textAlign: 'start', color: primaryColor }}
                    />
                  </div>
                  <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <TypographyCustom
                      variant={"h5"}
                      content="checkoutPaymentTotal"
                      style={{ textAlign: 'start' }}
                    />
                    <TypographyCustom
                      variant={"h5"}
                      content={`Rp ${formatPrice(orderForm.price + 2000)}`}
                      style={{ textAlign: 'start', color: primaryColor }}
                    />
                  </div>
                  <br />
                  <Button type="submit" style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
                    <TypographyCustom
                      content="checkoutForm"
                    />
                  </Button>
                  <div style={{ marginBlock: '0.6rem' }}>
                    <TypographyCustom
                      variant={"caption"}
                      content="or"
                    />
                  </div>
                  <a href={`${LINK_NUMBER_WA}?text=${textWaOrder(locale, vehicle, pickUpDate)}`} target='_blank' rel='noreferrer' style={{ width: "100%" }}>
                    <Button style={{ backgroundColor: greenColor, color: whiteColor, width: "100%" }}>
                      {/* <TypographyCustom
                        content=""
                      /> */}
                      <FormattedMessage id="itemOrderVehicle" /> Via WA
                    </Button>
                  </a>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Form>
        <ModalTnc
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </Container>
      <Footer />
    </div>
  );
};

export default Checkout;