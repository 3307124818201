import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ToggleLanguage from '../Toggle/ToggleLanguage';
import { LINK_NUMBER_WA, base_url_images, blackColor, fontFamily, greenColor, primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { WhatsApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { textWaOrder } from '../../Utils/functionUtils';
import { DataContext } from '../../Context/DataContext';

const pages = ['navbarHome', 'navbarSelectVehicle', 'navbarPriceList', 'navbarHelp'];
const endPoint = ['/', '/all-items', '/price-list', '/help']

const pagesAdmin = ['navbarHome', 'navbarTransaction', 'navbarOutcomeTransaction', 'navbarListVehicle'];
const endpointAdmin = ['/', '/transaction', '/outcome-transaction', '/list-vehicle']

const NavBar = (props) => {
  const { isLanding, title, isPageAdmin } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const { allState } = React.useContext(DataContext)
  const { locale } = allState;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (directions) => {
    setAnchorElNav(null);
    if (directions) {
      window.location.href = "." + directions;
    }
  };

  const handleCurrentPage = (endPoint) => {
    navigate(endPoint);
  }

  const handleLanding = () => {
    window.location.href = '/';
  }

  const navigateToBlog = () => {
    window.location.href = 'https://blog.mybalirentcar.com/';
  }

  return (
    <AppBar position="static" elevation={isLanding ? 0 : 1} style={{ backgroundColor: "rgba(255, 255, 255, 0)", height: "10%" }}>
      <Container maxWidth>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Box sx={{ flexGrow: 1 }}>
            <img alt='logo' sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} src={`${base_url_images}logo.png`} width={170} onClick={handleLanding} style={{ cursor: 'pointer' }} />
          </Box>

          <Box sx={{ flexGrow: -1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: blackColor }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {(isPageAdmin ? pagesAdmin : pages).map((page, index) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(isPageAdmin ? endpointAdmin[index] : endPoint[index])}>
                  <Typography style={{ fontFamily: fontFamily }} textAlign="center"><FormattedMessage id={page} /></Typography>
                </MenuItem>
              ))}
              <MenuItem key={"navbarBlog"} onClick={() => navigateToBlog()}>
                <Typography style={{ fontFamily: fontFamily }} textAlign="center"><FormattedMessage id="navbarBlog" /></Typography>
              </MenuItem>
              <MenuItem>
                <a href={`${LINK_NUMBER_WA}?text=${textWaOrder(locale)}`} target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
                  <Button variant="outlined" size='small' style={{ backgroundColor: greenColor, color: whiteColor, height: "50%", fontFamily: fontFamily }}>
                    <FormattedMessage id="whatsapp" />
                    <span>{' '}<WhatsApp /></span>
                  </Button>
                </a>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 6, display: { xs: 'none', md: 'flex' }, justifyContent: "center" }}>
            {(isPageAdmin ? pagesAdmin : pages).map((page, index) => (
              <Button
                key={page}
                onClick={() => handleCurrentPage(isPageAdmin ? endpointAdmin[index] : endPoint[index])}
                sx={{ my: 2, color: isLanding ? whiteColor : primaryColor, display: 'block', fontWeight: page === title ? '0' : '600', fontFamily: fontFamily }}
              >
                <FormattedMessage id={page} />
              </Button>
            ))}
            <Button
              key={"navbarBlog"}
              onClick={() => navigateToBlog()}
              sx={{ my: 2, color: isLanding ? whiteColor : primaryColor, display: 'block', fontWeight: '600', fontFamily: fontFamily }}
            >
              <FormattedMessage id="navbarBlog" />
            </Button>
            <a href={`${LINK_NUMBER_WA}?text=${textWaOrder(locale)}`} target='_blank' rel='noreferrer' style={{ textDecoration: "none" }}>
              <Button variant="outlined" size='small' style={{ backgroundColor: greenColor, color: whiteColor, borderRadius: "5rem", height: "50%", marginTop: "0.7rem", fontFamily: fontFamily }}>
                <FormattedMessage id="whatsapp" />

                <span>{' '}<WhatsApp /></span>
              </Button>
            </a>
          </Box>
          <ToggleLanguage />

        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default NavBar;