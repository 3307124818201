import './App.css';
import { DataProvider } from './Context/DataContext';
import RoutesPages from './RoutesPages';

function App() {
  return (
    <div className="App">
      <DataProvider>
        <RoutesPages />
      </DataProvider>
    </div>
  );
}

export default App;
