import api from "./api"

export const createCar = async (data) => {
  return await api.post('/car/create-car', data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  })
}

export const getAllCar = async () => {
  return await api.get('/car/get-all-car')
}

export const getBestCar = async () => {
  return await api.get('/car/get-best-car')
}

export const getCarById = async (id) => {
  return await api.get(`/car/get-car-by-id/${id}`)
}