import React, { useState } from 'react';
import {
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import CreateCar from '../../Components/Form/CreateCar';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Kendaraan', 'sub1', <UserOutlined />, [
    getItem('Tambah Kendaraan', '1'),
    getItem('Daftar Kendaraan', '2'),
  ]),
  getItem('Transaksi', 'sub2', <UserOutlined />, [
    getItem('Daftar Transaksi', '3'),
  ]),
];
const Admin = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            style={{
              marginTop: 36,
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <CreateCar />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Ant Design ©2023 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Admin;