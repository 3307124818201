import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { Button, Container, Paper, Typography } from '@mui/material';
import { Form, Input } from 'antd';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'
import { login } from '../../Components/api/user';
import { useContext } from 'react';
import { DataContext } from '../../Context/DataContext';

const Auth = () => {

  const { allState } = useContext(DataContext)
  const { setIsAuthenticated } = allState

  // Create swal object
  const MySwal = withReactContent(Swal);

  const handleFinish = (values) => {
    MySwal.showLoading()
    login(values)
      .then((res) => {
        MySwal.fire({
          title: <strong>Sign in Success</strong>,
          timer: 1000,
          showConfirmButton: false,
          icon: 'success'
        })

        const token = res.data.data.token;
        localStorage.setItem ('token', token);
        setIsAuthenticated(true);
      })
      .then((res) => {
        window.location.href = '/transaction';
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Authentication Failed</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }

  return (
    <div>
      <NavBar />
      <Container style={{ textAlign: 'center', width: "80%" }}>
        <Paper style={{ padding: "20px", marginTop: "40px" }} elevation={10}>
          <Typography variant='h5' style={{ color: primaryColor, fontWeight: '700' }}>
            Sign In
          </Typography>
          <br />
          <Form
            layout="vertical"
            onFinish={handleFinish}
          >
            <Form.Item label={'Username'} name={'username'} rules={[{ required: 'true' }]}>
              <Input placeholder={'Masukkan username'} />
            </Form.Item>
            <Form.Item label={'Password'} name={'password'} rules={[{ required: 'true' }]}>
              <Input placeholder={'Masukkan password'} type='password' />
            </Form.Item>
            <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
              <Typography>
                Sign In
              </Typography>
            </Button>
          </Form>
        </Paper>
      </Container>
    </div>
  );
};

export default Auth;