import { primaryColor } from "../../Utils/Constants";

export const styleContainer = {
  backgroundColor: primaryColor,
  width: '100%',
  justifyContent: 'center',
  position: "absolute",
  zIndex: "1",
};

export const styleSentences = {
  marginTop: "20px",
  color: "white",
  textDecoration: "underline",
}

export const styleTitle = {
  marginTop: "20px",
  color: "white",
  fontWeight: "bold",
  textTransform: 'uppercase'
}