import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { Container, Table, Typography, Hidden, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import FormCheckout from './FormCheckout';
import Paper from '@mui/material/Paper';
import { primaryColor, whiteColor } from '../../Utils/Constants';

const dataCart = [
  {
    title: "Tenda 2 Orang",
    price: "Rp. 50.000",
    image: "images/item/tenda2.jpg",
    quantity: "1"
  },
  {
    title: "Tenda 4 Orang",
    price: "Rp. 100.000",
    image: "images/item/tenda4.jpg",
    quantity: "2"
  },
  {
    title: "Carrier 40L",
    price: "Rp. 50.000",
    image: "images/item/carrier.jpg",
    quantity: "3"
  },
]


const Cart = (props) => {

  return (
    <div>
      <>
        <div >
          <NavBar />
          <Container style={{ marginTop: "20px", paddingBottom: "2%", backgroundColor: "white", textAlign: 'start' }}>
            <Hidden smDown>
              <Typography style={{ color: "black", fontSize: "2rem", marginTop: "30px", marginBottom: '20px' }} >
                Keranjang Anda
              </Typography>
              <hr />
            </Hidden>
            <Hidden mdUp>
              <Typography style={{ color: "black", fontSize: "2rem", marginTop: "100px", marginBottom: '10px' }} >
                Keranjang Anda
              </Typography>
              <br />
            </Hidden>
            <TableContainer component={Paper}>
              <Table style={{ textAlign: 'center' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Produk</TableCell>
                    <TableCell align="center">Kuantitas</TableCell>
                    <TableCell align="center">Gambar</TableCell>
                    <TableCell align="center">Harga Satuan</TableCell>
                    <TableCell align="center">Total Harga</TableCell>
                    {/* <thead>
                  <tr>
                    <th>Produk</th>
                    <th>Kuantitas</th>
                    <th>Gambar</th>
                    <th>Harga Satuan</th>
                    <th>Total Harga</th>
                  </tr>
                </thead> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    dataCart !== null && dataCart.map((cart, index) => {
                      return (
                        <TableRow
                          key={cart.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {cart.title}
                          </TableCell>
                          <TableCell align="center">
                            <Hidden smUp>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <button style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%' }}>-</button>
                                <button style={{ border: '0', width: '50%' }}>{cart.quantity}</button>
                                <button style={{ backgroundColor: primaryColor, border: '0', color: whiteColor, width: '50%' }}>+</button>
                              </div>
                            </Hidden>
                            <Hidden smDown>
                              <button style={{ backgroundColor: primaryColor, border: '0', color: whiteColor }}>-</button>
                              <button style={{ border: '0' }}>{cart.quantity}</button>
                              <button style={{ backgroundColor: primaryColor, border: '0', color: whiteColor }}>+</button>
                            </Hidden>
                          </TableCell>
                          <TableCell align="center"> <img alt='gambar' src={cart.image} style={{ width: "100px", height: "100px" }} /></TableCell>
                          <TableCell align="center">{cart.price}</TableCell>
                          <TableCell align="center">{cart.price}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <FormCheckout />
        </div>
      </>
    </div>
  );
};

export default Cart;