import React from 'react';
import { styled } from '@mui/material/styles';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Container, Table, TableContainer, TableHead, TableRow, TableBody, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontFamily, primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';
import { useState } from 'react';
import { getAllCar } from '../../Components/api/car';
import ModalDetailCar from '../../Components/Modal/ModalDetailCar';
import { Skeleton } from 'antd';
import { useContext } from 'react';
import { DataContext } from '../../Context/DataContext';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../Utils/functionUtils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styleText = {
  fontFamily: fontFamily
};

const PriceList = () => {
  const navigate = useNavigate();
  const { allState } = useContext(DataContext)
  const { setOrderForm } = allState;
  const [listCar, setListCar] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [carDetail, setCarDetail] = useState({});

  const handleOrder = () => {
    setOrderForm(prevOrderForm => ({
      ...prevOrderForm,
      vehicle: carDetail.name,
      typeVehicle: carDetail.type,
      idVehicle: carDetail.id,
      image: carDetail.image
    }));
    navigate('/checkout');
  }

  useEffect(() => {
    getAllCar()
      .then((res) => {
        setListCar(res.data.data);
      })
  }, [])

  const handleDetail = (car) => {
    setCarDetail(car);
    setModalOpen(true);
  }

  const generateEachSkeleton = (id) => {
    return (
      <StyledTableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StyledTableCell component="th" scope="row">
          <Skeleton loading={listCar.length === 0} active paragraph={0} />
        </StyledTableCell>
        <StyledTableCell><Skeleton loading={listCar.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={listCar.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={listCar.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={listCar.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={listCar.length === 0} active paragraph={0} /></StyledTableCell>
      </StyledTableRow>
    )
  }


  const generateSkeleton = () => {
    return (
      <>
        {generateEachSkeleton(1)}
        {generateEachSkeleton(2)}
        {generateEachSkeleton(3)}
        {generateEachSkeleton(4)}
        {generateEachSkeleton(5)}
      </>
    );
  }


  return (
    <div>
      <NavBar title="navbarPriceList" />
      <Container style={{ marginBlock: "2rem" }}>
        <TableContainer component={Paper}>
          <Table style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListVehicle" /></StyledTableCell>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListName" /></StyledTableCell>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListPricePerDay" /></StyledTableCell>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListPricePerMonth" /></StyledTableCell>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListTypeVehicle" /></StyledTableCell>
                <StyledTableCell style={styleText}><FormattedMessage id="priceListDetail" /></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listCar.length === 0 && generateSkeleton()}
              {
                listCar !== null && listCar.map((car, index) => {
                  return (
                    <StyledTableRow
                      key={car.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row"  style={styleText}>
                        {car.vehicle}
                      </StyledTableCell>
                      <StyledTableCell style={styleText}>{car.name}</StyledTableCell>
                      <StyledTableCell style={styleText}>Rp {formatPrice(car.pricePerDay)}</StyledTableCell>
                      <StyledTableCell style={styleText}>Rp {formatPrice(car.pricePerMonth)}</StyledTableCell>
                      <StyledTableCell style={styleText}>{car.type}</StyledTableCell>
                      <StyledTableCell style={styleText}><button style={{ color: primaryColor, border: 'none', cursor: 'pointer', backgroundColor: index % 2 === 1 ? whiteColor : null }} onClick={() => handleDetail(car)}>Detail</button></StyledTableCell>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Footer />
      <ModalDetailCar
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        image={carDetail.image}
        name={carDetail.name}
        priceDay={carDetail.pricePerDay}
        priceMonth={carDetail.pricePerMonth}
        seat={carDetail.maxPeople}
        fuel={carDetail.fuel}
        type={carDetail.type}
        handleOrderFromModal={handleOrder}
      />
    </div>
  );
};

export default PriceList;