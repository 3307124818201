import { createContext, useState } from "react";
import { IntlProvider } from 'react-intl';
import textId from '../I18n/text.id';
import textEn from '../I18n/text.en';
import jwtDecode from "jwt-decode";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        type: ""
    })

    const [orderForm, setOrderForm] = useState({
        location: "",
        pickUpDate: "",
        pickUpTime: "",
        dropOffDate: "",
        dropOffTime: "",
        idVehicle: "",
        vehicle: "",
        name: "",
        email: "",
        price: 0,
        phoneNumber: "",
        voucherCode: ""
    });

    const [order, setOrder] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    const [isAbout, setIsAbout] = useState(false);

    const [isLocation, setIsLocation] = useState(false);

    const [locale, setLocale] = useState(localStorage.getItem("lang") && localStorage.getItem("lang").includes("en") ? "en" : "id");

    const [text, setText] = useState(localStorage.getItem("lang") && localStorage.getItem("lang").includes("en") ? textEn : textId);

    const getToken = localStorage.getItem("token");

    const decoded = getToken ? jwtDecode(getToken) : {}
    const { isAdmin, exp } = decoded;
    const [isAuthenticated, setIsAuthenticated] = useState(isAdmin && exp > Date.now() / 1000);

    const allState = {
        user,
        setUser,
        locale,
        setLocale,
        text,
        setText,
        order,
        setOrder,
        orderForm,
        setOrderForm,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        isAbout,
        setIsAbout,
        isLocation,
        setIsLocation
    };
    return (
        <DataContext.Provider value={{ allState }}>
            <IntlProvider locale={locale} messages={text}>
                {children}
            </IntlProvider>
        </DataContext.Provider>
    )
}