import { Container, Typography, Button } from '@mui/material';
import { DatePicker, Form, Input } from 'antd';
import React from 'react';
import { primaryColor } from '../../Utils/Constants';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ubahHarga = (harga) => {
  if (harga === "0") {
    return "0";
  }
  return harga.substring(0, harga.length - 3) + '.' + harga.substring(harga.length - 3, harga.length);
}

const FormCheckout = () => {

  const handleCheckout = () => {
    MySwal.fire({
      title: <strong>Berhasil di Pesan</strong>,
      html: <div> <p>Lihat Menu Bookingan Saya untuk Melihat Kode Booking Anda </p> </div>,
      timer: 5000,
      showConfirmButton: false,
      icon: 'success'
    })
  }
  return (
    <div>
      <Container style={{ textAlign: 'start' }}>
        <Typography variant='h5' style={{ marginBottom: '30px' }}>
          Lengkapi Data Diri Anda
        </Typography>
        <Form
          layout='vertical'
        >
          <Form.Item name="date-picker" label="Tanggal Sewa">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Nama Lengkap">
            <Input type="text" placeholder='Nama Lengkap' />
          </Form.Item>
          <Form.Item label="Alamat">
            <Input type="text" placeholder='Alamat' />
          </Form.Item>
          <Form.Item label="Nomor Telepon">
            <Input type="text" placeholder='0812345678' />
          </Form.Item>
          <Form.Item label="Email">
            <Input type="text" placeholder='tes@gmail.com' />
          </Form.Item>
          <div style={{ marginTop: "2%", paddingTop: "2%", paddingBottom: "2%", backgroundColor: "white", textAlign: 'start' }}>
            <div style={{ display: "inline" }}>
              <Typography variant="h5" style={{ marginTop: "10px", display: "inline", marginRight: "10px" }}>
                Total ({8} produk)
              </Typography>
              <Typography variant="h5" style={{ marginTop: "10px", color: "#307606", display: "inline" }}>
                Rp {ubahHarga(String(10000))}
              </Typography>
            </div>
            <div>
              <Button onClick={handleCheckout} style={{ backgroundColor: primaryColor, color: "white", marginTop: "20px", width: '25%' }}>Pesan</Button>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default FormCheckout;