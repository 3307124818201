import React, { useEffect } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import BodyListItem from './BodyListItem';
import { Affix } from 'antd';
import { Button } from '@mui/material';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import Footer from '../../Components/Footer/Footer';

import { DataContext } from '../../Context/DataContext'
import { useNavigate } from 'react-router-dom';

const ListItemRent = () => {
  const navigate = useNavigate();
  const { allState } = React.useContext(DataContext)

  useEffect(() => {
  }, [allState.order])

  const handleViewCart = () => {
    window.location.href = '/cart'
  }

  const navigateToCheckout = () => {
    navigate('/checkout');
  }

  return (
    <div>
      <NavBar title="navbarSelectVehicle" />
      <BodyListItem navigateToCheckout={navigateToCheckout} />
      <Footer />
      {
        allState.order && (
          <Affix offsetBottom={10}>
            <Button onClick={handleViewCart} style={{ backgroundColor: primaryColor, color: whiteColor, marginLeft: '20px', width: '50%', borderRadius: "14rem" }} variant="contained" key="submit" type="primary">
              Lihat Keranjang Anda
            </Button>
          </Affix>
        )
      }
    </div>
  );
};

export default ListItemRent;