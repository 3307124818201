import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { LINK_NUMBER_WA, fontFamily, greenColor, primaryColor, redColor } from '../../../Utils/Constants';
import { DataContext } from '../../../Context/DataContext';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useContext } from 'react';
import { formatPrice, textWaOrder } from '../../../Utils/functionUtils';
import ModalDetailCar from '../../Modal/ModalDetailCar';

const StyledCard = styled(Card)(({ theme }) => ({
  paddingTop: '1rem',
  cursor: 'pointer',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19)',
    transform: 'scale(1.05)',
  },
}));

export default function CardItem(props) {
  const { id, name, priceDay, priceMonth, priceDayUp, priceMonthUp, image, seat, fuel, type, navigateToCheckout, priceFont, ready } = props;
  const { allState } = useContext(DataContext)
  const { setOrderForm, locale, orderForm } = allState;
  const [modalOpen, setModalOpen] = useState(false);

  const handleOrder = () => {
    setOrderForm(prevOrderForm => ({
      ...prevOrderForm,
      vehicle: name,
      typeVehicle: type,
      idVehicle: id,
      image: image,
      price: priceDay,
    }));
    navigateToCheckout();
  }

  const handleShowModal = () => {
    setModalOpen(true);
  }

  const handleDetail = (id) => {
    setModalOpen(true);
  }

  return (
    <div>
      <StyledCard sx={{ maxWidth: 375 }}>
        <CardMedia
          sx={{ height: 140 }}
          image={`images/car/${image}`}
          title="car item"
          onClick={() => handleDetail(id)}
          style={{ backgroundSize: 'contain' }}
        />
        <CardContent sx={{ height: 70, textAlign: 'center' }} onClick={() => handleDetail(id)} >
          <Typography sx={{ height: 60, textAlign: 'center' }} variant="h6" component="div" style={{ color: primaryColor, fontWeight: '700', fontFamily: 'Montserrat, sans-serif' }}>
            {name.toUpperCase()}
          </Typography>
          <Typography variant="body1" component="div" style={{ fontWeight: '700', fontFamily: 'Montserrat, sans-serif' }}>
            Matic
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'block', width: '100%', textAlign: 'center' }} onClick={() => handleShowModal()}>

          <Typography gutterBottom variant={priceFont} component="div" style={{ color: primaryColor, fontFamily: fontFamily }}>
            <s>{' Rp '}{formatPrice(priceDayUp)}</s> {' Rp '}{formatPrice(priceDay)} / hari
          </Typography>
          <Typography gutterBottom variant="caption" component="div" style={{ fontFamily: fontFamily }}>
            <s>{' Rp '}{formatPrice(priceMonthUp)}</s> {' Rp '}{formatPrice(priceMonth)} / bulan
          </Typography>
          <Typography gutterBottom variant="h6" component="div" style={{ marginTop: '1rem', fontFamily: fontFamily }}>
            Seat : {seat}
          </Typography>
        </CardActions>
        {
          ready ? <div style={{ display: 'flex' }}>
            <Button onClick={() => handleOrder()} size="small" variant="contained" style={{ backgroundColor: primaryColor, color: 'white', width: '100%', fontFamily: fontFamily }}>
              <FormattedMessage id="itemOrderVehicle" /> VIA WEB
            </Button>
            <a href={`${LINK_NUMBER_WA}?text=${textWaOrder(locale, name, orderForm.pickUpDate)}`} target='_blank' rel='noreferrer' style={{ width: "100%", textDecoration: "none" }}>
              <Button size="small" variant="contained" style={{ backgroundColor: greenColor, color: 'white', width: '100%', fontFamily: fontFamily }}>
                <FormattedMessage id="itemOrderVehicle" /> Via WA
              </Button>
            </a>
          </div> : <Button disabled size="small" variant="contained" style={{ backgroundColor: redColor, color: 'white', width: '100%', fontFamily: fontFamily }}>
            Unavailable
          </Button>
        }
        <ModalDetailCar
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          image={image}
          name={name}
          priceDay={priceDay}
          priceMonth={priceMonth}
          seat={seat}
          fuel={fuel}
          type={type}
          handleOrderFromModal={handleOrder}
        />
      </StyledCard >
    </div>
  );
}