import { Box, Container, Grid, Hidden, Typography } from '@mui/material';
import React from 'react';
import { fontFamily, primaryColor } from '../../Utils/Constants';
import { CheckCircleSharp } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import TypographyCustom from '../../Components/Typography/TypographyCustom';


const benefit = [
  {
    title: "benefitHeaderOne",
    caption: "benefitCaptionOne"
  },
  {
    title: "benefitHeaderTwo",
    caption: "benefitCaptionTwo"
  },
  {
    title: "benefitHeaderThree",
    caption: "benefitCaptionThree"
  }
]

const Benefit = () => {
  return (
    <div id='benefit'>
      <Box 
        sx={{
          width: '100%',
          backgroundImage: {xs: 'none', sm: `url("images/bg-body.png")`},
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}>
        <Container maxWidth>
          <Container style={{ display: "flex", width: "100%", justifyContent: "center", paddingBlock: '2%' }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={5} >
                <Hidden smDown>
                  <img src="images/mobile-body.png" alt="car" style={{ width: "100%", marginLeft: "-10rem" }} />
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={6} md={7}>
                <Typography variant='h4' style={{ fontFamily: fontFamily, fontWeight: "bold", textAlign: "start", color: primaryColor }}>
                  <FormattedMessage id='bodyJargon' />
                </Typography>
                <div style={{ textAlign: "start", marginTop: "40px" }}>
                  {
                    benefit.map((item, id) => {
                      return (
                        <Grid key={id} container style={{ marginBottom: "15px" }}>
                          <Grid item xs={2} sm={2} md={1}>
                            <div style={{
                              alignItems: "center"
                            }}>
                              <CheckCircleSharp style={{ color: primaryColor }} fontSize='large' />
                            </div>
                          </Grid>
                          <Grid item xs={10} sm={10} md={11}>
                            <TypographyCustom
                              variant={"h5"}
                              content={item.title}
                              style={{ fontWeight: "500", color: primaryColor }}
                            />
                            <TypographyCustom
                              variant={"body2"}
                              content={item.caption}
                              style={{ fontWeight: "500" }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </div>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
    </div>
  );
};

export default Benefit;