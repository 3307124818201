import api from "./api"

export const createTransaction = async (data) => {
  return await api.post('/transaction/create-transaction', data);
}

export const createTransactionAdmin = async (data) => {
  return await api.post('/transaction/create-transaction-admin', data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getTransactionActive = async () => {
  return await api.get('/transaction/get-transaction-active', {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getAllTransaction = async () => {
  return await api.get('/transaction/get-all-transaction', {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getAllTransactionV2 = async (page, pageSize) => {
  return await api.get(`/transaction/get-all-transaction-v2?page=${page}&pageSize=${pageSize}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getHistoryTransaction = async (id) => {
  return await api.get(`/transaction/get-history-transaction-user/${id}`);
}

export const updateStatusTransaction = async (id, data) => {
  return await api.patch(`/transaction/update-status-transaction/${id}`, data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const deleteTransaction = async (id) => {
  return await api.delete(`/transaction/delete-transaction/${id}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}