import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { Form, Input, Select, Skeleton } from 'antd';
import { Button, Container, Grid, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { primaryColor, redColor, whiteColor } from '../../Utils/Constants';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'
import { deleteOutcomeTransaction } from '../../Components/api/outcomeTransaction';
import { createVehicle, getAllVehicle } from '../../Components/api/vehicle';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Vehicle = () => {
  const [form] = Form.useForm();
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setLoading(true);
    getAllVehicle(page, PAGE_SIZE)
      .then((res) => {
        setTransaction(res.data.data.data);
        setTotalPage(Math.ceil(res.data.data.totalData / PAGE_SIZE));
        setLoading(false);
      })
  }, [page])

  const handleSubmit = (values) => {
    MySwal.showLoading()
    let notvalid = false;
    if (values.name === '' || values.policeNumber === ''
      || values.name === undefined || values.policeNumber === undefined) {
      notvalid = true;
    }
    if (notvalid) {
      MySwal.fire({
        title: <strong>Failed Create Vehicle</strong>,
        html: <i>Vehicle Name and Police Number cannot be empty</i>,
        showConfirmButton: false,
        timer: 1500,
        icon: 'error'
      })
    } else {
      createVehicle(values)
        .then((res) => {
          MySwal.fire({
            title: <strong>Create Vehicle Success</strong>,
            timer: 1500,
            showConfirmButton: false,
            icon: 'success'
          })
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          MySwal.fire({
            title: <strong>Failed Create Vehicle</strong>,
            html: <i>{err.response.data.message}</i>,
            showConfirmButton: false,
            timer: 1500,
            icon: 'error'
          })
        })
    }
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: <strong>Are you sure?</strong>,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: redColor,
      cancelButtonColor: primaryColor,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.showLoading()
        deleteOutcomeTransaction(id)
          .then(() => {
            MySwal.fire({
              title: <strong>Delete Outcome Transaction Success</strong>,
              timer: 1500,
              showConfirmButton: false,
              icon: 'success'
            })
            window.location.reload();
          })
      }
    })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Delete Outcome Transaction</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }


  const generateEachSkeleton = (id) => {
    return (
      <StyledTableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StyledTableCell component="th" scope="row">
          <Skeleton loading={transaction.length === 0} active paragraph={0} />
        </StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
      </StyledTableRow>
    )
  }

  const generateSkeleton = () => {
    return (
      <>
        {generateEachSkeleton(1)}
        {generateEachSkeleton(2)}
        {generateEachSkeleton(3)}
        {generateEachSkeleton(4)}
        {generateEachSkeleton(5)}
      </>
    );
  }

  const handlePagination = (event, value) => {
    setPage(value);
  }

  return (
    <div>
      <NavBar isPageAdmin={true} />
      <Container style={{ textAlign: 'center', width: "80%", marginTop: "20px" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'name'} label={'Vehicle'}>
                <Input placeholder={'Agya'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'policeNumber'} label={'Police Number'} >
                <Input placeholder={'DK 123 AB'} />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'category'} label={'Type'}>
                <Select
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  options={
                    [
                      { label: 'CAR', value: 'CAR' },
                      { label: 'MOTORCYCLE', value: 'MOTORCYCLE' }
                    ]
                  }
                  defaultValue={'MOTORCYCLE'}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'type'} label={'Type'}>
                <Select
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  options={
                    [
                      { label: 'MATIC', value: 'MATIC' },
                      { label: 'MANUAL', value: 'MANUAL' },
                      { label: 'ELECTRIC', value: 'ELECTRIC' }
                    ]
                  }
                  defaultValue={'SERVICE'}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'pricePerDay'} label={'Price'} >
                <Input placeholder={'80000'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'pricePerMonth'} label={'Price'} >
                <Input placeholder={'800000'} />
              </Form.Item>
            </Grid>
          </Grid>
          <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
            <TypographyCustom
              content="headerFormSubmit"
            />
          </Button>
        </Form>
      </Container>

      <Container style={{ marginBlock: "2rem" }}>
        <TableContainer component={Paper}>
          <Table style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Vehicle</StyledTableCell>
                <StyledTableCell>Police Number</StyledTableCell>
                <StyledTableCell>type</StyledTableCell>
                <StyledTableCell>Price per Day</StyledTableCell>
                <StyledTableCell>Price per Month</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && generateSkeleton()}
              {
                transaction !== null && !loading && transaction.map((car, index) => {
                  return (
                    <StyledTableRow
                      key={car.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{car.name}</StyledTableCell>
                      <StyledTableCell>{car.policeNumber}</StyledTableCell>
                      <StyledTableCell>{car.type}</StyledTableCell>
                      <StyledTableCell>{car.pricePerDay}</StyledTableCell>
                      <StyledTableCell>{car.pricePerMonth}</StyledTableCell>
                      <StyledTableCell>
                        <button
                          style={{ color: whiteColor, border: 'none', cursor: 'pointer', backgroundColor: redColor }}
                          onClick={() => handleDelete(car.id)}>
                          Delete
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ textAlign: 'center', marginTop: "1rem", display: 'flex' }}>
          <Pagination count={totalPage} page={page} onChange={handlePagination} showFirstButton showLastButton />
        </div>
      </Container>
    </div>
  );
};

export default Vehicle;