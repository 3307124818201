import api from "./api"

export const createVehicle = async (data) => {
  return await api.post('/vehicle/create-vehicle', data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  })
}

export const getAllVehicle = async () => {
  return await api.get('/vehicle/get-all-vehicle', {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getVehicleById = async (id) => {
  return await api.get(`/vehicle/get-vehicle-by-id/${id}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}