import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import { Modal } from 'antd';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import { CalendarMonth, CalendarToday, ElectricCar, LocalGasStation, MotionPhotosAuto, People } from '@mui/icons-material';
import { formatPrice } from '../../Utils/functionUtils';

const ModalDetailCar = (props) => {
    const { modalOpen, setModalOpen, image, handleOrderFromModal, seat, fuel, type, name, priceDay, priceMonth } = props;
    return (
        <Modal
            // title="Vertically centered modal dialog"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={800}
            footer={[
                <Button style={{ backgroundColor: whiteColor, color: primaryColor }} variant="contained" key="back" onClick={() => setModalOpen(false)}>
                    Kembali
                </Button>,
                <Button style={{ backgroundColor: primaryColor, color: whiteColor, marginLeft: '20px' }} variant="contained" key="submit" type="primary" onClick={handleOrderFromModal}>
                    <FormattedMessage id="order" />
                </Button>
            ]}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <img src={`images/car/${image}`} alt="gambar" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
                        <Typography variant='h5' style={{ color: primaryColor }}>
                            Harga
                        </Typography>
                        <Typography variant='body1'>
                            {/* <span> */}
                            <CalendarToday fontSize='small' />
                            {/* </span> */}
                            {' '}
                            {`${formatPrice(priceDay)} / hari`}
                        </Typography>
                        <Typography variant='body1'>
                            <CalendarMonth fontSize='small' />
                            {' '}
                            {`${formatPrice(priceMonth)} / bulan`}
                        </Typography>
                        <br />
                        <Typography variant='h5' style={{ color: primaryColor }}>
                            Spesifikasi
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography variant='body1'>
                                    <People fontSize='small' />
                                    {' '}
                                    {`${seat} orang`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='body1'>
                                    <LocalGasStation fontSize='small' />
                                    {' '}
                                    {`${fuel}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='body1'>
                                    <ElectricCar fontSize='small' />
                                    {' '}
                                    City Car
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant='body1'>
                                    <MotionPhotosAuto fontSize='small' />
                                    {' '}
                                    {`${type}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <div draggable={true} style={{ maxHeight: "300px", overflow: "auto" }}>
                <Box sx={{ display: { xs: 'inline', md: 'none' } }}>
                    <Typography variant='h5' style={{ color: primaryColor }}>
                        Harga
                    </Typography>
                    <Typography variant='body1'>
                        {/* <span> */}
                        <CalendarToday fontSize='small' />
                        {/* </span> */}
                        {' '}
                        {`${formatPrice(priceDay)} / hari`}
                    </Typography>
                    <Typography variant='body1'>
                        <CalendarMonth fontSize='small' />
                        {' '}
                        {`${formatPrice(priceMonth)} / bulan`}
                    </Typography>
                    <br />
                    <Typography variant='h5' style={{ color: primaryColor }}>
                        Spesifikasi
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1'>
                                <People fontSize='small' />
                                {' '}
                                {`${seat} orang`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1'>
                                <LocalGasStation fontSize='small' />
                                {' '}
                                {`${fuel}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1'>
                                <ElectricCar fontSize='small' />
                                {' '}
                                City Car
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1'>
                                <MotionPhotosAuto fontSize='small' />
                                {' '}
                                {`${type}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant='h5' style={{ color: primaryColor }}>
                    Keuntungan menyewa {name && name.toLowerCase()} di Bali?
                </Typography>
                <Typography variant='body1' style={{ textAlign: 'justify' }}>
                    Karena memiliki desain layaknya mobil SUV, menjadikan {name && name.toLowerCase()}  sebagai mobil MPV terbaik dikelasnya. Mobil bertenaga 1.500CC ini punya kabin luas dengan 7 bangku penumpang yang sangat nyaman dan ruang bagasi yang lega. Buktinya, Bagasi {name && name.toLowerCase()}  mampu menampung hingga 4 galon air. Tidak hanya itu, {name && name.toLowerCase()}  juga dilengkapi dengan fitur AC double blower dan Port charging pada setiap baris penumpang.
                </Typography>
            </div>
        </Modal>
    );
};

export default ModalDetailCar;