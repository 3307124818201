import api from "./api"

export const validateKtp = async (data) => {
    return await api.post('/ktp/validate', data, {
        headers: {
            Authorization: localStorage.getItem("token")
        }
    })
}

export const loginKtp = async () => {
    return await api.get('/ktp/login', {
        headers: {
            Authorization: localStorage.getItem("token")
        }
    })
}