import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Modal } from 'antd';
import { blackColor, primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import TypographyCustom from '../Typography/TypographyCustom';

const listTnc = [
  "",
  "",
  "",
  "",
  "",
  ""
]

const ModalTnc = (props) => {
    const { modalOpen, setModalOpen } = props;
    return (
        <Modal
            // title="Vertically centered modal dialog"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={800}
            footer={[
                <Button style={{ backgroundColor: whiteColor, color: primaryColor }} variant="contained" key="back" onClick={() => setModalOpen(false)}>
                    Kembali
                </Button>
            ]}
        >
           <div>
              <div style={{ textAlign: "center" }}>
                <TypographyCustom
                    variant={"h6"}
                    content="tncTitle"
                    style={{ color: primaryColor, fontWeight: "700" }}
                  />
              </div>
              <br />
              <div draggable={true} style={{ maxHeight: "300px", overflow: "auto" }}>
                {
                  Array.from({length: 12}, () => 0).map((item, index) => {
                    return (
                      <Grid container>
                        <Grid item xs={1} md={0.5}>
                          <Typography style={{ marginTop: "-2px"}}>
                            {index + 1}.
                          </Typography>
                        </Grid>
                        <Grid item xs={11}>
                          <TypographyCustom
                              variant={"body2"}
                              content={`tnc${index + 1}`}
                              style={{ color: blackColor}}
                            />
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </div>
           </div>
           
        </Modal>
    );
};

export default ModalTnc;