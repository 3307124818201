import React, { useContext } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import { FormattedMessage } from 'react-intl';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { DataContext } from '../../Context/DataContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const Order = () => {
  const { allState } = useContext(DataContext);
  const { orderForm, setOrderForm } = allState;
  const navigate = useNavigate();

  const generateButtonChange = (content) => {
    return (
      <Button onClick={handleNavigateToCheckout} size="small" variant="contained" style={{ backgroundColor: whiteColor, color: primaryColor, width: '100%', marginBlock: "1rem" }}>
        <FormattedMessage id={content} />
      </Button>
    );
  }

  const handleNavigateToCheckout = () => {
    navigate('/checkout');
  }

  return (
    <div>
      <NavBar />
      <Box sx={{ marginBlock: {xs: '0px', sm: '2rem'} }}>
        <Container style={{ textAlign: "start"}}>
          <Grid container spacing={5}>
            <Grid item xs={0} md={2}>
            </Grid>
            <Grid item xs={12} md={8}>
              <TypographyCustom
                variant="h6"
                content="orderMyOrder"
              />
              <Typography variant='body1'>
                <FormattedMessage id="orderNumber" />
                <span style={{ color: primaryColor }}>: ABC231D988</span>
              </Typography>
              <Divider />
              <Typography variant='h6' style={{ color: primaryColor, fontWeight: "700", marginTop: "1rem" }}>
                <FormattedMessage id="orderStatus" />
                <span>: <FormattedMessage id="orderWaitingPayment" /></span>
              </Typography>
              <Typography variant='h5'>
                Rp 92.000
              </Typography>
              <Typography variant='caption' style={{ color: primaryColor }}>
                <FormattedMessage id="orderTransferBefore" />
                <span> 10 Agustus 2013 01.33</span>
              </Typography>
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <div>
                  <Typography variant='h6' style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id={orderForm.payment} />
                    {' '}
                    <FormattedMessage id="accountBankName" />
                  </Typography>
                  <Typography variant='h5'>
                    6485242701
                  </Typography>
                </div>
                <img src={`images/payment/${orderForm.payment}.png`} alt={orderForm.payment} width={100} />
              </div>
              {generateButtonChange("orderChangePaymentMethod")}
              <Divider style={{ marginBottom: "1rem" }} />
              <div>
                <TypographyCustom
                  variant="caption"
                  content="checkoutName"
                />
                <Typography variant='subtitle2'>
                  {orderForm.name}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutEmail"
                />
                <Typography variant='subtitle2'>
                  {orderForm.email}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutPhoneNumber"
                />
                <Typography variant='subtitle2'>
                  {orderForm.phoneNumber}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="checkoutVehicle"
                />
                <Typography variant='subtitle2'>
                  {`${orderForm.vehicle} (${orderForm.typeVehicle})`}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormPickUp"
                />
                <Typography variant='subtitle2'>
                  {`${moment(orderForm.pickUpDate).format('DD MMMM YYYY')} ${moment(orderForm.pickUpTime.$d).format('HH:mm')}`}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormDropOff"
                />
                <Typography variant='subtitle2'>
                  {`${moment(orderForm.dropOffDate).format('DD MMMM YYYY')} ${moment(orderForm.dropOffTime.$d).format('HH:mm')}`}
                </Typography>
                <br />
                <TypographyCustom
                  variant="caption"
                  content="headerFormPickUpLocation"
                />
                <Typography variant='subtitle2'>
                  {orderForm.location}
                </Typography>
                <br />
              </div>
              {generateButtonChange("orderChangeOrderDetail")}
              <Divider style={{ marginBottom: "1rem" }} />
              <img src={`images/car/${orderForm.image}`} alt="car" style={{ width: "100%" }} />
              {generateButtonChange("orderChangeVehicle")}
              <Divider style={{ marginBottom: "1rem" }} />
            </Grid>
            <Grid item xs={0} md={2}>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default Order;