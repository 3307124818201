import { Button, Typography } from '@mui/material';
import { Form, Input, Select } from 'antd';
import React from 'react';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { createCar } from '../api/car';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'

const optionVehicle = [
  {
    value: 'CAR',
    label: 'Mobil',
  },
  {
    value: 'MOTORCYCLE',
    label: 'Motor',
  },
];

const optionType = [
  {
    value: 'MANUAL',
    label: 'Manual',
  },
  {
    value: 'MATIC',
    label: 'Automatic',
  },
  {
    value: 'ELECTRIC',
    label: 'Electric',
  }
];

const optionFuel = [
  {
    value: 'PERTALITE',
    label: 'Pertalite',
  },
  {
    value: 'PERTAMAX',
    label: 'Pertamax',
  },
  {
    value: 'ELECTRIC',
    label: 'Electric',
  },
  {
    value: 'SOLAR',
    label: 'Solar',
  }
];

const CreateCar = () => {

  // Create swal object
  const MySwal = withReactContent(Swal);

  const handleFinish = (values) => {
    const carData = {
      name: values.nama,
      vehicle: values.kendaraan || 'CAR',
      type: values.type || 'MATIC',
      pricePerDay: parseInt(values.pricePerDay),
      pricePerMonth: parseInt(values.pricePerMonth),
      stock: 1,
      maxPeople: parseInt(values.maxPeople),
      fuel: values.fuel || 'PERTALITE',
      whyRentId: 'test',
      whyRentEn: 'test'
    }
    MySwal.showLoading()
    createCar(carData)
      .then((res) => {
        MySwal.fire({
          title: <strong>Tambah Kendaraan Sukses</strong>,
          timer: 1500,
          showConfirmButton: false,
          icon: 'success'
        })
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Gagal Tambah Kendaraan</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }

  return (
    <div>
      <Typography variant='h5' style={{ color: primaryColor, fontWeight: '700' }} >
        Tambah Kendaraan
      </Typography>
      <Form
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item label={'Nama'} name={'nama'} rules={[{ required: 'true' }]}>
          <Input placeholder={'Masukkan nama'} />
        </Form.Item>
        <Form.Item label={'Kendaraan'} name={'kendaraan'}>
          <Select
            style={{ textAlign: "left" }}
            showSearch
            optionFilterProp="children"
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionVehicle}

            defaultValue={'CAR'}
          />
        </Form.Item>
        <Form.Item label={'Type'} name={'type'}>
          <Select
            style={{ textAlign: "left" }}
            showSearch
            optionFilterProp="children"
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionType}
            defaultValue={'MATIC'}
          />
        </Form.Item>
        <Form.Item label={'Harga per Hari'} name={'pricePerDay'} rules={[{ required: 'true' }]}>
          <Input placeholder={'100000'} />
        </Form.Item>
        <Form.Item label={'Harga per Bulan'} name={'pricePerMonth'} rules={[{ required: 'true' }]}>
          <Input placeholder={'200000'} />
        </Form.Item>
        <Form.Item label={'Kapasitas Mobil'} name={'maxPeople'} rules={[{ required: 'true' }]}>
          <Input placeholder={'Masukkan password'} type='number' />
        </Form.Item>
        <Form.Item label={'Bahan Bakar'} name={'fuel'} >
          <Select
            style={{ textAlign: "left" }}
            showSearch
            optionFilterProp="children"
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionFuel}
            defaultValue={'PERTALITE'}
          />
        </Form.Item>
        <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
          <Typography>
            Tambah
          </Typography>
        </Button>
      </Form>
    </div>
  );
};

export default CreateCar;