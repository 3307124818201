import React from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import Landing from "./Pages/Landing/Landing";
import ListItemRent from "./Pages/ListItemRent/ListItemRent";
import Cart from "./Pages/Cart/Cart";
import Order from "./Pages/Order/Order";
import Checkout from "./Pages/Checkout/Checkout";
import PriceList from "./Pages/PriceList/PriceList";
import Help from "./Pages/Help/Help";
import Auth from "./Pages/Auth/Auth";
import Admin from "./Pages/Admin/layout";
import { useContext } from "react";
import { DataContext } from "./Context/DataContext";
import Transaction from "./Pages/Transaction/Transaction";
import OutcomeTransaction from "./Pages/OutcomeTransaction/OutcomeTransaction";
import Vehicle from "./Pages/Vehicle/Vehicle";

const RoutesPages = () => {
    const { allState } = useContext(DataContext)
    const { isAuthenticated } = allState

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/all-items" element={<ListItemRent />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/order" element={<Order />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/price-list" element={<PriceList />} />
                <Route path="/help" element={<Help />} />
                <Route path="/admin/auth" element={<Auth />} />
                <Route path="/admin" element={isAuthenticated ? <Admin /> : <Navigate to='/admin/auth' />} />
                <Route path="/transaction" element={isAuthenticated ? <Transaction /> : <Navigate to='/admin/auth' />} />
                <Route path="/outcome-transaction" element={isAuthenticated ? <OutcomeTransaction /> : <Navigate to='/admin/auth' />} />
                <Route path="/list-vehicle" element={isAuthenticated ? <Vehicle /> : <Navigate to='/admin/auth' />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesPages;