import React, { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Container, Divider, Grid, Hidden, MenuItem, Select } from '@mui/material';
import CardItem from '../../Components/Card/CardItem/CardItem';
import { Input, Skeleton, Space } from 'antd';
import { SearchOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { getAllCar } from '../../Components/api/car';

const styleContainer = {
  marginBottom: "40px"
};

const listItemVehicleValue = [
  {
    name: "listItemVehicleAll",
    value: "ALL"
  },
  {
    name: "listItemVehicleItem1",
    value: "MOTORCYCLE",
  },
  {
    name: "listItemVehicleItem2",
    value: "CAR",
  }
];

const listItemTypeVehicleValue = [
  {
    name: "listItemTypeVehicleAll",
    value: "ALL"
  },
  {
    name: "listItemTypeVehicleItem1",
    value: "MATIC",
  },
  {
    name: "listItemTypeVehicleItem2",
    value: "MANUAL",
  },
  {
    name: "listItemTypeVehicleItem3",
    value: "ELECTRIC",
  }
];

const listItemVehicle = listItemVehicleValue.map((item) => item.name);

const listItemTypeVehicle = listItemTypeVehicleValue.map((item) => item.name);

const BodyListItem = (props) => {
  const { navigateToCheckout } = props;

  const [vehicle, setVehicle] = useState(['listItemVehicleAll']);
  const [vehicleValue, setVehicleValue] = useState('ALL');
  const [typeVehicle, setTypeVehicle] = useState(['listItemTypeVehicleAll']);
  const [typeVehicleValue, setTypeVehicleValue] = useState('ALL');
  const [keyword, setKeyword] = useState('');

  const [listCar, setListCar] = useState([]);
  const [listCarBackup, setListCarBackup] = useState([]);

  useEffect(() => {
    getAllCar()
      .then((res) => {
        setListCar(res.data.data);
        setListCarBackup(res.data.data);
      })
  }, [])

  useEffect(() => {
    const filteredCar = listCarBackup.filter((item) => {
      if (vehicle.includes('listItemVehicleAll') && typeVehicle.includes('listItemTypeVehicleAll')) {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      } else if (vehicle.includes('listItemVehicleAll') && !typeVehicle.includes('listItemTypeVehicleAll')) {
        return item.name.toLowerCase().includes(keyword.toLowerCase()) && typeVehicleValue.includes(item.type);
      } else if (!vehicle.includes('listItemVehicleAll') && typeVehicle.includes('listItemTypeVehicleAll')) {
        return item.name.toLowerCase().includes(keyword.toLowerCase()) && vehicleValue.includes(item.vehicle);
      } else {
        return item.name.toLowerCase().includes(keyword.toLowerCase()) && vehicleValue.includes(item.vehicle) && typeVehicleValue.includes(item.type);
      }
    });
    setListCar(filteredCar);
  }, [vehicle, typeVehicle, keyword])


  const handleChange = (type, title) => {
    let newArray = [];
    // type === 'vehicle' ? setVehicle([...vehicle, title]) : setTypeVehicle([...typeVehicle, title]);
    if (type === 'vehicle') {
      newArray = [...vehicle];
      if (title === 'listItemVehicleAll' && !vehicle.includes(title)) {
        setVehicle(['listItemVehicleAll']);
        return;
      }
      if (vehicle.includes('listItemVehicleAll') && title !== 'listItemVehicleAll') {
        const index = vehicle.indexOf('listItemVehicleAll');
        if (index > -1) {
          newArray.splice(index, 1);
        }
      }
      if (vehicle.includes(title)) {
        const index = vehicle.indexOf(title);
        if (index > -1) {
          newArray.splice(index, 1);
        }
      } else {
        newArray.push(title)
      }
      setVehicle(newArray);
      setVehicleValue(newArray.length === 0 ? 'ALL' : listItemVehicleValue.filter((item) => newArray.includes(item.name)).map((item) => item.value));
    } else {
      newArray = [...typeVehicle];
      if (title === 'listItemTypeVehicleAll' && !typeVehicle.includes(title)) {
        setTypeVehicle(['listItemTypeVehicleAll']);
        return;
      }
      if (typeVehicle.includes('listItemTypeVehicleAll') && title !== 'listItemTypeVehicleAll') {
        const index = typeVehicle.indexOf('listItemTypeVehicleAll');
        if (index > -1) {
          newArray.splice(index, 1);
        }
      }
      if (typeVehicle.includes(title)) {
        const index = typeVehicle.indexOf(title);
        if (index > -1) {
          newArray.splice(index, 1);
        }
      } else {
        newArray.push(title)
      }
      setTypeVehicle(newArray);
      setTypeVehicleValue(newArray.length === 0 ? 'ALL' : listItemTypeVehicleValue.filter((item) => newArray.includes(item.name)).map((item) => item.value));

    }
  };

  const handleChangeSelectVehicle = (event) => {
    const newArray = [event.target.value];
    setVehicle(newArray);
    setVehicleValue(newArray.length === 0 ? 'ALL' : listItemVehicleValue.filter((item) => newArray.includes(item.name)).map((item) => item.value));
  };

  const handleChangeSelectTypeVehicle = (event) => {
    const newArray = [event.target.value];
    setTypeVehicle(newArray);
    setTypeVehicleValue(newArray.length === 0 ? 'ALL' : listItemTypeVehicleValue.filter((item) => newArray.includes(item.name)).map((item) => item.value));
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }

  const generateFormGroup = (listTitle, type) => {
    return (
      <FormGroup style={{ marginBottom: "10px" }}>
        {
          listTitle.map((title, index) => {
            return generateFormControlLabel(title, index, type);
          })
        }
      </FormGroup>
    );
  };

  const generateFormControlLabel = (title, key, type) => {
    return (
      <FormControlLabel
        control={
          <Checkbox checked={vehicle.includes(title) || typeVehicle.includes(title)} key={key} onChange={() => handleChange(type, title)} name={title} />
        }
        label={<FormattedMessage id={title} />}
      />
    );
  }

  const generateFormSelect = (type, listTitle) => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={type === 'vehicle' ? vehicle[0] : typeVehicle[0]}
          defaultValue={type === 'vehicle' ? vehicle[0] : typeVehicle[0]}
          onChange={type === 'vehicle' ? handleChangeSelectVehicle : handleChangeSelectTypeVehicle}
        // inputProps={{ 'aria-label': 'Without label' }}
        >
          {
            listTitle.map((item, index) => {
              return (
                <MenuItem key={index} value={item}><FormattedMessage id={item} /></MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    );
  }
  return (
    <>
      <Container style={styleContainer} grid>
        <div style={{ marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} >
              <Hidden smDown>
                <div style={{ textAlign: 'start' }}>
                  <FormControl sx={{ m: 3 }} >
                    <FormLabel><FormattedMessage id='listItemVehicle' /></FormLabel>
                    <Divider />
                    {generateFormGroup(listItemVehicle, 'vehicle')}
                    {/* <FormHelperText>Be careful</FormHelperText> */}
                  </FormControl>
                </div>
                <div style={{ textAlign: 'start' }}>
                  <FormControl sx={{ m: 3 }}>
                    <FormLabel><FormattedMessage id='listItemTypeVehicle' /></FormLabel>
                    <Divider />
                    {generateFormGroup(listItemTypeVehicle, 'typeVehicle')}
                    {/* <FormHelperText>Be careful</FormHelperText> */}
                  </FormControl>
                </div>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9} style={{ textAlign: 'start' }}>
              <Hidden smUp>
                <div style={{ justifyContent: 'space-between', display: 'flex', marginBottom: '10px', marginTop: '-20px' }}>
                  {generateFormSelect('vehicle', listItemVehicle)}
                  {generateFormSelect('typeVehicle', listItemTypeVehicle)}
                </div>
              </Hidden>
              <Space.Compact size="large" style={{ width: '100%', marginBottom: "30px" }} >
                <Input value={keyword} onChange={handleChangeKeyword} style={{ backgroundColor: primaryColor, borderRadius: "8px" }} addonBefore={<SearchOutlined style={{ color: whiteColor }} />} placeholder="Cari Kendaraan" />
              </Space.Compact>
              {
                listCar.length === 0
                  ? <Grid container spacing={4}>
                    <Grid key={1} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                    <Grid key={2} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                    <Grid key={3} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                    <Grid key={1} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                    <Grid key={2} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                    <Grid key={3} item xs={12} sm={6} md={4} lg={4}><Skeleton loading={listCar.length === 0} active /></Grid>
                  </Grid>
                  : null
              }
              <Grid container spacing={3}>
                {
                  listCar.map((item, index) => {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <CardItem
                          name={item.name}
                          priceDay={item.pricePerDay}
                          priceMonth={item.pricePerMonth}
                          priceDayUp={item.pricePerDayUp}
                          priceMonthUp={item.pricePerMonthUp}
                          image={item.image}
                          seat={item.maxPeople}
                          fuel={item.fuel}
                          type={item.type}
                          ready={item.ready}
                          id={item.id}
                          navigateToCheckout={navigateToCheckout}
                          priceFont="body1"
                        />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container >
    </>
  );
};

export default BodyListItem;