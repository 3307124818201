import React, { useContext, useEffect, useRef } from 'react';
import Footer from '../../Components/Footer/Footer';
// import Blog from './Blog';
import ItemRent from './ItemRent';
import Header from '../../Components/Header/Header';
import Benefit from './Benefit';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { DataContext } from '../../Context/DataContext';

const Landing = () => {
  const navigate = useNavigate();
  const { allState } = useContext(DataContext)
  const { isLoading, isAbout } = allState;
  const aboutRef = useRef(null);

  useEffect(() => {
    if (isAbout) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAbout])

  const navigateToAllItems = () => {
    navigate('/all-items')
  }

  const navigateToCheckout = () => {
    navigate('/checkout')
  }

  return (
    <div>
      <Spin
        size='large'
        spinning={isLoading}
        style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <Header navigateToAllItems={navigateToAllItems}/>
        <div ref={aboutRef}>
          <Benefit />
        </div>
        <ItemRent navigateToCheckout={navigateToCheckout}/>
        {/* <Blog /> */}
        <Footer />
      </Spin>
    </div>
  );
};

export default Landing;