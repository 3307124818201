import { Box, Button, Container, Grid, Hidden, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import NavBar from '../NavBar/NavBar';
import { DatePicker, Form, Input, TimePicker } from 'antd';
import { fontFamily, primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage, useIntl } from 'react-intl';
import TypographyCustom from '../Typography/TypographyCustom';
import { Fade } from 'react-reveal';
import { DataContext } from '../../Context/DataContext';

const Header = (props) => {
  const intl = useIntl();
  const { allState } = useContext(DataContext);
  const { setOrderForm } = allState;
  const { navigateToAllItems } = props;

  const handleOrder = (values) => {
    setOrderForm({
      location: values.location,
      pickUpDate: values.pickUpDate,
      pickUpTime: values.pickUpTime,
      dropOffDate: values.dropOffDate,
      dropOffTime: values.dropOffTime
    });
    navigateToAllItems();
  };

  return (
    <>
      <Box 
        sx={{
          width: '100%',
          backgroundImage: {xs: 'none', sm: `url("images/bg-header.png")`},
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginBottom: {xs: '50px', sm: '0px'}
        }}>
        <Container maxWidth>
          <NavBar isLanding={true} title="navbarHome" />
          <br />
          <Container style={{ paddingBottom: '1%', marginTop: "-10px" }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <Fade duration={2000} bottom distance="20%">
                  <div style={{  }}>
                    <Box sx={{ width: '100%',display: 'flex', justifyContent: 'center', marginTop: {xs: '0px', sm: '40px'}}}>
                    <Paper style={{ padding: "20px", width: "80%" }} elevation={10}>
                      <TypographyCustom
                        variant={"h5"}
                        content="headerFormRentNow"
                        style={{ color: primaryColor, fontWeight: "700" }}
                      />
                      <br />
                      <Form
                        layout="vertical"
                        onFinish={handleOrder}
                      >
                        <Form.Item style={{ fontFamily: fontFamily}} name={'location'} label={<FormattedMessage id='headerFormPickUpLocation' />} >
                          <Input placeholder={intl.formatMessage({ id: 'headerFormPickUpLocationPlaceholder' })} />
                        </Form.Item>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Form.Item style={{ fontFamily: fontFamily}} name='pickUpDate' label={<FormattedMessage id='headerFormPickUp' />}>
                              <DatePicker
                                placeholder={intl.formatMessage({ id: 'hedaerFormPickUpPlaceHolder' })}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Form.Item style={{ fontFamily: fontFamily}} name='pickUpTime' label={' '}>
                              <TimePicker
                                placeholder={intl.formatMessage({ id: 'hedaerFormPickUpTimePlaceHolder' })}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Form.Item style={{ fontFamily: fontFamily}} name='dropOffDate' label={<FormattedMessage id='headerFormDropOff' />}>
                              <DatePicker
                                placeholder={intl.formatMessage({ id: 'headerFormDropOffPlaceHolder' })}
                                style={{ width: "100%", marginRight: "3%" }}
                              />
                            </Form.Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Form.Item style={{ fontFamily: fontFamily}} name='dropOffTime' label={' '}>
                              <TimePicker
                                placeholder={intl.formatMessage({ id: 'headerFormDropOffTimePlaceHolder' })}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Grid>
                        </Grid>
                        <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%", fontFamily: fontFamily }}>
                          <TypographyCustom
                            content="headerFormContinueBooking"
                          />
                        </Button>
                        <Hidden smUp>
                        <a href='/price-list' style={{textAlign: "end", textDecoration: "none", color: primaryColor, cursor: 'pointer'}}>
                          <TypographyCustom
                            content="seePriceList"
                            style={{fontSize: "10px", marginTop: "10px", fontFamily: fontFamily}}
                          />
                        </a>
                        </Hidden>
                      </Form>
                    </Paper>
                    </Box>
                  </div>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Hidden smDown>
                  <Fade duration={2000} bottom distance="20%">
                    <Typography variant='h4' style={{ color: whiteColor, fontFamily: fontFamily, fontWeight: "bold", marginLeft: "50px" }}>
                      <span style={{ fontSize: '40px', fontWeight: "bold" }}>
                        <FormattedMessage id='welcomeHeaderPartOne' />
                      </span>
                      {' '}
                      <span style={{ fontSize: '70px', color: primaryColor, fontWeight: "900" }}>
                        <FormattedMessage id='welcomeHeaderPartTwo' />
                      </span>
                      {' '}
                      <span style={{ fontWeight: "700" }}>
                        <FormattedMessage id='welcomeHeaderPartThree' />
                      </span>
                    </Typography>
                  </Fade>
                  <Fade duration={2500} bottom distance="20%">
                    <img src="images/car-header.png" alt="car" style={{ width: "100%", marginTop: "-30px" }} />
                  </Fade>
                </Hidden>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box >
    </>
  );
};

export default Header;